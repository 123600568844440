import axios from "axios";
import { useDispatch } from "react-redux";
import { clearStore, refreshToken } from "../features/user/UserSlice";

const live = axios.create({
  // baseURL: "http://143.110.249.95:8080/",
  baseURL: "https://api.emplux.com/",
  withCredentials: true,
});
const local = axios.create({
  baseURL: "http://192.168.24.124:8080/",
  withCredentials: true,
});

export const krutrimFetch = axios.create({
  baseURL: "https://api.olamaps.io",
  withCredentials: true,
});

const customFetch = live; // local,live
// Custom hook to set up interceptors
export const useAxiosInterceptors = () => {
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(clearStore());
  };
  // Interceptor for customFetch
  customFetch.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      console.log("originalRequest", originalRequest);
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        console.log("refreshing token 1");
        try {
          const callRefreshToken = await dispatch(refreshToken());
          console.log(callRefreshToken);
          const newToken = callRefreshToken?.payload?.jwtToken;
          console.log("newToken", newToken);
          if (newToken) {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

            return customFetch(originalRequest);
          } else {
            Logout();
          }
        } catch (tokenRefreshError) {
          // Handle error during token refresh
          Logout();
        }
      }
      return Promise.reject(error); // Reject the promise with the error
    }
  );
};
export const dummyFetch = "";
export default customFetch;
