import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import customFetch from "../../utils/axios";
import { logoutUser } from "../user/UserSlice";

const initialState = {
  isLoadingCatg: false,
  totalPages: null,
  catagory: [],
};

export const getCatagories = createAsyncThunk(
  "catagry/getcatagories",
  async (queryParams, thunkAPI) => {
    // const { accessToken } = getUserFromLocalStorage();
    console.log("get");
    try {
      const resp = await customFetch.get(`api/category/${0}`, {
        params: queryParams,
      });
      return { data: resp?.data, page: queryParams?.page };
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logoutUser());
        return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
      }
      return thunkAPI.rejectWithValue(error.response.msg);
    }
  }
);

// export const postCatagory = createAsyncThunk(
//   "catagory/postcatagory",
//   async (data, thunkAPI) => {
//     console.log(data);
//     const { accessToken } = getUserFromLocalStorage();

//     try {
//       const resp = await customFetch.post("api/category", data, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error.response) {
//         alert("Error Occured!");
//       }
//       return thunkAPI.rejectWithValue(error.response.msg);
//     }
//   }
// );

// export const updateCategory = createAsyncThunk(
//   "catagory/updatecategory",
//   async (data, thunkAPI) => {
//     console.log(data);
//     const { accessToken } = getUserFromLocalStorage();
//     try {
//       const resp = await customFetch.put(`api/category/${data.id}`, data, {
//         headers: {
//           authorization: `Bearer ${accessToken}`,
//         },
//       });
//       return resp.data;
//     } catch (error) {
//       if (error.response) {
//         alert("Error Occured!");
//       }
//       return thunkAPI.rejectWithValue(error.response.msg);
//     }
//   }
// );

const CatagorySlice = createSlice({
  name: "catagory",
  initialState,
  reducers: {
    setLoadingCatg: (state, { payload }) => {
      state.isLoadingCatg = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(postCatagory.fulfilled, (state) => {
      //   alert("Catagory Successfully Added!");
      //   state.isLoadingCatg = false;
      // })
      // .addCase(postCatagory.pending, (state) => {
      //   state.isLoadingCatg = true;
      // })
      // .addCase(postCatagory.rejected, (state) => {
      //   state.isLoadingCatg = false;
      // })
      .addCase(getCatagories.fulfilled, (state, { payload }) => {
        if (payload.page > 0) {
          state.catagory = [...state.catagory, ...payload.data?.content];
        } else {
          state.catagory = payload.data?.content;
        }
        state.totalPages = payload?.data?.totalPages;
        // alert(payload.message);
        state.isLoadingCatg = false;
      })
      .addCase(getCatagories.pending, (state) => {
        state.isLoadingCatg = true;
      })
      .addCase(getCatagories.rejected, (state) => {
        state.isLoadingCatg = false;
      });
    // .addCase(updateCategory.fulfilled, (state) => {
    //   alert("Updated Successfully!");
    //   state.isLoadingCatg = false;
    // })
    // .addCase(updateCategory.pending, (state) => {
    //   state.isLoadingCatg = true;
    // })
    // .addCase(updateCategory.rejected, (state) => {
    //   state.isLoadingCatg = false;
    // });
  },
});
export default CatagorySlice.reducer;
export const { setLoadingCatg } = CatagorySlice.actions;
