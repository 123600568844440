import React, { useState } from "react";
import "../../style/loader.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ObjectLoader from "../ObjectLoader";
import { motion } from "framer-motion";
import SubCategoryPopup from "./SubCategoryPopup";
import TestimonialsCarousel from "./TestimonialsCarousel";
import HeroSection from "./HeroSection";

const Desktop = ({
  data,
  category,
  SubCategories,
  handleCategoryIdChange,
  CategoryId,
  home,
  handleScrolledCategory,
  categoryContainerRef,
  isLoadingCatg,
}) => {
  const navigate = useNavigate();
  const { isLoadingSubCatg } = useSelector((store) => store.subcategory);
  const { isLoadingHome } = useSelector((store) => store.home);
  const [popup, setPopup] = useState(false);

  //########################################################

  const handleCategoryClick = (id) => {
    handleCategoryIdChange(id);
    setPopup(true);
  };
  const catgForHome = category.filter(
    (elemment) => elemment.categoryType === "Electronics"
  );

  const handleCatagoryScroll = (position) => {
    if (categoryContainerRef.current) {
      categoryContainerRef?.current?.scrollBy({
        left: position === "right" ? 500 : -500, // Adjust the amount to scroll by
        behavior: "smooth", // Add smooth scrolling behavior if desired
      });
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setCurrentTestimonial((prev) => (prev + 1) % testimonials?.length);
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className=" w-full overflow-hidden  ">
      {/* Popups */}

      {/* sub catagory popup */}
      {popup && (
        <SubCategoryPopup
          isLoadingSubCatg={isLoadingSubCatg}
          handleSubcategory={(flag) => setPopup(flag)}
          category={category}
          CategoryId={CategoryId}
          SubCategories={SubCategories}
        />
      )}

      {/* Background image */}
      <HeroSection />

      {/* categories */}
      <div className="flex flex-col justify-center  mt-[2rem] gap-[2rem] h-max mb-[5rem]">
        <div className="font-medium max-xl:w-4/5 w-5/6 m-auto text-xl text-gray-600 max-lg:text-lg">
          Your doorstep, Our service...
        </div>
        <div className="flex items-center justify-center gap-[2vw] h-full ">
          <IoIosArrowForward
            className="  rotate-180 text-[#676565] h-10 w-10 cursor-pointer hover:bg-neutral-100 rounded-full p-2 ml-4 "
            onClick={() => handleCatagoryScroll("left")}
          />
          <div
            id="category"
            onScroll={handleScrolledCategory}
            className="flex items-center  gap-[2vw] overflow-x-scroll w-[84vw] min-h-max no-scrollbar scroll-smooth scroll-mr-8 "
            ref={categoryContainerRef}>
            {category?.length > 0 &&
              category?.map((element, index) => {
                const {
                  id,
                  categoryType,
                  categoryUrl,
                  showCategory,
                  categoryName,
                } = element;

                if (showCategory) {
                  return (
                    <motion.div
                      animate={{ scale: 1 }}
                      initial={{ scale: 0 }}
                      onClick={() => handleCategoryClick({ id })}
                      className="flex flex-col justify-center items-center h-[11.25rem] max-lg:h-[8.25rem] cursor-pointer border min-w-[11.25rem] max-w-[11.25rem] max-lg:min-w-[9.25rem] max-lg:max-w-[9.25rem] rounded-2xl border-primary-600 text-[#0000008b]  overflow-hidden hover:scale-95 transition ease-in-out duration-150 "
                      key={index}>
                      {isLoadingCatg}
                      <img
                        src={categoryUrl}
                        className="h-[5rem] w-[5rem] object-contain"
                      />
                      <div className="mt-4 font-semibold">{categoryName}</div>
                    </motion.div>
                  );
                }
              })}
            {(isLoadingCatg || category?.length <= 0) && (
              <div className="flex flex-col justify-center items-center h-[11.25rem] max-lg:h-[8.25rem] cursor-pointer border min-w-[11.25rem] max-w-[11.25rem] max-lg:min-w-[9.25rem] max-lg:max-w-[9.25rem] rounded-2xl border-primary-600 text-[#0000008b]  overflow-hidden hover:scale-95 transition ease-in-out duration-150 ">
                <ObjectLoader />
              </div>
            )}
          </div>
          <IoIosArrowForward
            className="  text-[#676565] h-10 w-10 cursor-pointer hover:bg-neutral-100 rounded-full p-2 mr-4"
            onClick={() => handleCatagoryScroll("right")}
          />
        </div>
      </div>

      {/* mian thumbnail */}
      {/* Home data */}
      <div className="px-[10vw]">
        {!isLoadingHome && home?.length > 0 ? (
          home?.map((element, index) => {
            if (element?.completed) {
              if (
                !element?.bannerImgUrl &&
                element?.homeSubSections?.length > 0
              ) {
                return (
                  <div
                    className="h-full mt-[8.5rem] max-lg:mt-[5.5rem] flex flex-col expand outfit gap-12 w-full  "
                    key={index}>
                    <motion.h1
                      whileInView={{ x: 0, opacity: 1 }}
                      initial={{ x: -100, opacity: 0 }}
                      viewport={{ once: true }}
                      className="text-black  font-normal text-3xl  capitalize ">
                      {element?.title}
                    </motion.h1>
                    <div
                      className={
                        "bg max-lg:overflow-x-auto no-scrollbar " +
                        (element?.orientation === "tall"
                          ? "flex  gap-[1.375rem]   "
                          : "flex  gap-[2.25rem]")
                      }>
                      {element?.homeSubSections
                        ?.slice(0, 5)
                        ?.map((elem, indx) => {
                          return (
                            <motion.div
                              whileInView={{ x: 0, scale: 1 }}
                              initial={{ x: -100, scale: 0 }}
                              viewport={{ once: true }}
                              transition={{ duration: 0.1 }}
                              onClick={() =>
                                navigate(`./service/${elem?.subCategoryId}`)
                              }
                              key={indx}
                              className={
                                "hover:-translate-y-1 hover:scale-105  hover:drop-shadow-xl transition-all ease-in-out  flex-1 duration-300 cursor-pointer " +
                                (element?.orientation === "tall"
                                  ? " min-w-[10rem] "
                                  : "  min-w-[16rem] ")
                              }>
                              <img
                                src={elem?.thumbnailUrl}
                                alt=""
                                className="h-full w-full object-fill rounded-[0.625rem]"
                              />
                            </motion.div>
                          );
                        })}
                    </div>
                  </div>
                );
              } else if (element?.bannerImgUrl) {
                return (
                  <div
                    onClick={() => navigate(element?.interLinkUrl || "/")}
                    className="flex justify-center rounded-lg mt-[8.625rem] max-lg:mt-[5.5rem]   cursor-pointer w-full transition-all ease-in-out duration-300 delay-150 hover:drop-shadow-xl drop-primary-20  "
                    key={index}>
                    <motion.img
                      src={element?.bannerImgUrl}
                      whileInView={{ scale: 1 }}
                      initial={{ scale: 0.75 }}
                      viewport={{ once: true }}
                      alt=""
                      className=" object-cover block w-full"
                    />
                  </div>
                );
              }
            }
          })
        ) : (
          <div className="w-full h-96">
            <ObjectLoader />
          </div>
        )}

        {/* Testimonials */}

        <TestimonialsCarousel />
      </div>

      {/* Featured service */}
    </div>
  );
};

export default Desktop;
