import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetchDummy from "../../utils/axios";
import { logoutUser } from "../user/UserSlice";
import customFetch from "../../utils/axios";
import { getUserFromLocalStorage } from "../../utils/localStorage";

const initialState = {
  isLoadingHome: false,
  home: [],
  homeSubSection: {},
  testimonial: [],
};

// Get Api

export const getHome = createAsyncThunk("home/gethome", async (thunkAPI) => {
  try {
    const resp = await customFetch.get("api/home");
    const homeResp = [];
    if (resp) {
      for (const endpoint of resp.data) {
        if (
          endpoint?.bannerImgUrl === null ||
          endpoint?.bannerImgUrl?.toLowerCase() === "string"
        ) {
          try {
            const subSecResp = await customFetch.get(
              `api/home/sub-section/${endpoint?.id}`
            );
            homeResp.push({
              ...subSecResp.data.content[0],
              orientation: endpoint?.orientation,
            });
          } catch (error) {
            console.log(error);
            continue;
          }
        } else {
          homeResp.push(endpoint);
        }
      }
    }

    return homeResp;
  } catch (error) {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
      console.log(error.code);
    }
    if (error.response.status === 401) {
      thunkAPI.dispatch(logoutUser());

      return thunkAPI.rejectWithValue("Unauthorization! Logging out... ");
    }
    return thunkAPI.rejectWithValue(error.message);
  }
});

// get sub sectionn
export const getSubHome = createAsyncThunk(
  "home/getsubhome",
  async (homeId, thunkAPI) => {
    const { accessToken } = getUserFromLocalStorage();
    try {
      const resp = await customFetch.get(`api/home/sub-section/${homeId}`);

      return resp.data;
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        console.log(error.code);
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// get testimonials
export const getTestimonial = createAsyncThunk(
  "home/getTestimonial",
  async (_, thunkAPI) => {
    try {
      const resp = await customFetch.get("/api/testimonial");
      return resp?.data;
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        console.log(error.code);
      }
    }
  }
);

const CompanySlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    clearHome: (state) => {
      state.isLoadingHome = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getHome.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(getHome.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;

        state.home = payload;
      })
      .addCase(getHome.rejected, (state) => {
        state.isLoadingHome = false;
      })

      .addCase(getSubHome.fulfilled, (state, { payload }) => {
        state.homeSubSection = payload?.content?.slice(0, 1)[0];
      })

      .addCase(getTestimonial.pending, (state) => {
        state.isLoadingHome = true;
      })
      .addCase(getTestimonial.fulfilled, (state, { payload }) => {
        state.isLoadingHome = false;
        state.testimonial = payload;
      })
      .addCase(getTestimonial.rejected, (state) => {
        state.isLoadingHome = false;
      });
    // .addCase(updateIssue.pending, (state) => {
    //   state.isLoadingHome = true;
    // })
    // .addCase(updateIssue.fulfilled, (state, { payload }) => {
    //   alert("Issue Updated Successfully!");
    //   state.isLoadingHome = false;
    // })
    // .addCase(updateIssue.rejected, (state) => {
    //   state.isLoadingHome = false;
    // });
  },
});

export const { handleChange, clearHome } = CompanySlice.actions;
export default CompanySlice.reducer;
