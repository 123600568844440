import React from "react";

const NotificationsList = ({ className }) => {
  const notifications = [
    {
      id: 0,
      userId: "string",
      eventType: "string",
      title: "string",
      message: "string",
      timestamp: "2024-11-08T10:46:42.135Z",
      read: true,
    },
    {
      id: 1,
      userId: "string",
      eventType: "string",
      title: "string",
      message: "string",
      timestamp: "2024-11-08T10:46:42.135Z",
      read: true,
    },
  ];

  const Notification = ({ notification, key }) => {
    return (
      <div
        key={key}
        className="flex w-full border border-[#00000033] pr-3 gap-5 pb-5">
        <div className="min-w-[3rem] w-12 aspect-square rounded-full bg-primary-max"></div>

        <div className="flex flex-col gap-3 text-[#000000CC]">
          <h2 className="text-sm font-semibold">{notification.title}</h2>
          <p className="text-xs">{notification.message}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={"flex flex-col gap-9 " + className}>
      <div className="mb-3 flex w-full justify-between items-center text-lg font-bold text-primary-max">
        <h2>Notifications</h2>
        <h2>{notifications?.filter((n) => !n.read)?.length}</h2>
      </div>

      {notifications.map((notification, index) => (
        <Notification key={index} notification={notification} />
      ))}
    </div>
  );
};

export default NotificationsList;
