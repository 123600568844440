import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Mobile from "../../components/Service/Mobile";
import Desktop from "../../components/Service/Desktop";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchIssue, setLoadingIssue } from "../../features/Issue/IssuesSlice";
import {
  clearCart,
  deleteCart,
  getCart,
  postCart,
} from "../../features/Cart/CartSlice";
import { toast } from "sonner";
import { CommingSoonLayout } from "../../components";
import PageLoader from "../../components/PageLoader";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import ObjectLoader from "../../components/ObjectLoader";

const Service = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subCatgId } = useParams();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const [clickedId, setClickId] = useState(null);

  const { issuesList, subCatagory, error } = useSelector(
    (state) => state.issue
  );

  const { cartItems } = useSelector((state) => state.cart);
  const { isLoadingIssue } = useSelector((state) => state.issue);

  const checkEmptyCart = () => {
    const target = cartItems?.slice(0, 1)[0]?.issueId;
    const isTargetPresent = issuesList?.some((obj) => obj.id === target);
    return !isTargetPresent;
  };
  console.log(window.innerWidth);
  const updateMedia = () => {
    console.log("from update media" + window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };

  const handleProductClick = async (isseuId) => {
    setClickId(isseuId);
    const data = issuesList?.find((item) => item.id == isseuId);
    const localuser = getUserFromLocalStorage();
    if (!localuser?.accessToken) {
      toast.dismiss();
      toast("Please login to add to cart", {
        richColors: true,
        action: {
          label: "Login",
          actionButtonStyle: "background-color: #f8feff",
          onClick: () => navigate("/login"),
        },
      });
      return;
    }
    // if (cartItems?.find((item) => item.id == isseuId)) {
    if (cartItems?.find((item) => item.issueName == data.issueName)) {
      dispatch(
        deleteCart(
          cartItems?.find((item) => item.issueName == data.issueName).id
        )
      );
      return;
    } else if (checkEmptyCart()) {
      await dispatch(
        clearCart(
          cartItems?.map((item) => {
            return item.id;
          })
        )
      );
    }

    if (data) {
      dispatch(
        postCart({
          issueId: isseuId,
          issueName: data?.issueName,
          issueDuration: data?.issueDuration,
          issuePrice: data?.issuePrice,
          issueDescription: data?.issueDescription,
          issueIcon: data?.issueIconUrl,
          issueAppIcon: data?.appIssueIconUrl,
          active: true,
          subCategoryId: subCatgId,
        })
      );
    } else toast("Issue not found!");

    // setTimeout(() => {
    //   dispatch(getCart());
    // }, 3000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    dispatch(setLoadingIssue());
    dispatch(fetchIssue(subCatgId));
    dispatch(getCart());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(setLoadingIssue());
      navigate("/404");
    }
  }, [error]);
  if (isLoadingIssue !== false) {
    return (
      <div className=" w-full h-screen flex items-center justify-center ">
        <ObjectLoader />
      </div>
    );
  }
  return (
    <div>
      {issuesList?.length <= 0 ? (
        <CommingSoonLayout />
      ) : isDesktop ? (
        <Desktop
          subCatgId={subCatgId}
          issuesList={issuesList}
          subCatg={subCatagory}
          handleProductClick={handleProductClick}
          clickedId={clickedId}
        />
      ) : (
        <Mobile
          subCatgId={subCatgId}
          issuesList={issuesList}
          subCatg={subCatagory}
          handleProductClick={handleProductClick}
          clickedId={clickedId}
        />
      )}
    </div>
  );
};

export default Service;
