import React, { useState, useEffect, useRef } from "react";
import "../../style/home.css";
import Desktop from "../../components/Home/Desktop";
import Mobile from "../../components/Home/Mobile";
import {
  clearHome,
  getHome,
  getTestimonial,
} from "../../features/Home/homeSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCatagories } from "../../features/Catagory/CatagorySlice";
import { fetchSubCategory } from "../../features/SubCategory/SubCategorySlice";

const Home = () => {
  const dispatch = useDispatch();
  const categoryContainerRef = useRef();

  const { catagory, isLoadingCatg, totalPages } = useSelector(
    (state) => state.catagory
  );
  const { SubCategories } = useSelector((state) => state.subcategory);
  const { home, isLoadingHome } = useSelector((state) => state.home);

  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [categoryId, setCategoryid] = useState(null);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  // console.log(home, isLoadingHome);

  useEffect(() => {
    const handleScroll = () => {
      if (isLoadingHome) {
        const maxScrollableHeight = window.innerHeight;
        const currentScrollPosition = window.scrollY;

        if (currentScrollPosition > maxScrollableHeight) {
          window.scrollTo(0, maxScrollableHeight);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoadingHome]);

  const handleCategoryIdChange = ({ id }) => {
    console.log(id);
    setCategoryid(id);
  };

  // scroll to load catagory
  const handleScrolledCategory = () => {
    const { scrollLeft, scrollWidth, clientWidth } =
      categoryContainerRef.current;
    if (
      scrollLeft + clientWidth >= scrollWidth - 5 &&
      totalPages - 1 > page &&
      !isLoadingCatg
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    console.log("page changed :" + page + " : " + totalPages);
    if (page > 0) {
      dispatch(getCatagories({ page: page, size: 10 }));
    } else {
      dispatch(getCatagories());
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(clearHome());
        await dispatch(getHome());
        await dispatch(getTestimonial());
      } catch (error) {}
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (categoryId > 0) {
      dispatch(fetchSubCategory(categoryId));
    }
  }, [categoryId]);

  // useEffect(() => {
  //   if (home.length >= 0 && isSubSection == false) {
  //     dispatch(getSubsection(home));
  //   }
  // }, [isLoadingHome]);

  return (
    <div>
      {isDesktop ? (
        <Desktop
          data={users}
          category={catagory}
          SubCategories={SubCategories}
          handleCategoryIdChange={handleCategoryIdChange}
          CategoryId={categoryId}
          home={home}
          handleScrolledCategory={handleScrolledCategory}
          categoryContainerRef={categoryContainerRef}
          isLoadingCatg={isLoadingCatg}
        />
      ) : (
        <Mobile
          data={users}
          category={catagory}
          SubCategories={SubCategories}
          handleCategoryIdChange={handleCategoryIdChange}
          CategoryId={categoryId}
          home={home}
        />
      )}
    </div>
  );
};

export default Home;
